
@import '../../assets/style/flexible.scss';

.goodsList {
  height: 100%;
  background: #f4f4f4;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  .repostHeader {
    top: 0;
    position: fixed;
    width: 100%;
  }

  .reposReultList {
    width: 100%;
    padding: 0 PX(35);
    padding-top: PX(190);
    box-sizing: border-box;
    .item {
      font-size: PX(32);
      width: 100%;
      height: PX(373);
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      margin-bottom: PX(30);
      border: 1px solid #ddd;
      box-shadow: 0 2px 5px 0 rgba(184, 184, 184, 0.171);
      .medName {
        height: PX(72);
        display: flex;
        align-items: center;
        padding: 0 PX(19);
        box-sizing: border-box;
        letter-spacing: PX(1.2);
        color: #979696;
        justify-content: space-between;
        .checkIcon {
          height: PX(39);
          width: PX(39);
          float: right;
        }
        .IconName {
          display: flex;
        }
        img {
          height: PX(42);
          width: PX(42);
          margin-right: PX(20);
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: repeating-linear-gradient(
          90deg,
          transparent 0px,
          transparent 4px,
          #cdcccc 4px,
          #cdcccc 11px);
        // background: -webkit-repeating-linear-gradient( 180deg,transparent, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -moz-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -o-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
      }
      .detailParams {
        flex: 1;
        display: flex;
        font-size: PX(28);
        flex-direction: column;
        justify-content: space-between;
        padding: PX(16) PX(20);
        box-sizing: border-box;
        .param {
          display: flex;
          justify-content: space-between;
          .paramName {
            color: #979696;
          }
        }
      }
    }
  }
  .goodsListBtton {
    color: #fff;
    border-radius: 50px;
    font-size: PX(39);
    background-color: #0081e9;
    width: 100%;
    height: PX(80);
    box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
    outline: none;
    border: none;
    width: PX(400);
    margin-top: 0;
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    bottom: PX(30);
  }
}