@import '../../../assets/style/flexible.scss';

.issueDetail {
  font-size: PX(26);
  .issueDetailTitle {
    padding: PX(20) PX(30);
    img {
      height: PX(26);
      width: PX(26);
      margin-right: PX(10);
    }
  }

  .issueDetailContent {
    padding: 0 PX(40);
    font-weight: 300;
    line-height: PX(36);
    color: #666;
    font-size: PX(30);
  }
}