@import '../../../assets/style/flexible.scss';

.orderDetail {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background: #f4f4f4;
  padding: 0 PX(40);
  padding-top: PX(140);
  box-sizing: border-box;
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    left: 0;
  }
  .orderItem {
    // width: PX(500);
    min-height: PX(500);
    width: 100%;
    background: #fff;
    border-radius: PX(16);
    font-size: PX(26);
    color: #888;
    padding: PX(16) PX(36);
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: PX(20);
    justify-content: space-between;
    .storeName {
      display: flex;
      align-items: center;
      margin-top: PX(16);
      flex: 1;
      white-space: normal;
      .storeIcon {
        height: PX(33);
        width: PX(33);
        margin-right: PX(15);
      }
      .remarkImg{
         width: PX(130);
         height: PX(130);
         margin-right: PX(20);
         margin-bottom: PX(20);
      }
      .storeValueCol {
        width: PX(150);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .red {
        color: rgb(248, 37, 0);
      }
      .storeTitle {
        white-space: nowrap;
      }
      .phoneIcon {
        width: PX(40);
        height: PX(40);
      }
    }
  
    .storeNameBlock {
      display: block;
      label {
        float: left;
      }
      .remarkImg {
        float: left;
      }
    }

    .time-state {
      display: flex;
      align-items: center;
      .stateType {
        position: absolute;
        right: PX(20);
        color: orange;
        top: PX(20);
        padding-top: PX(10);
        img {
          height: PX(170);
          width: PX(170);
        }
      }
    }
    .icon-detail {
      display: flex;
      margin-top: PX(10);
      position: relative;
      margin-bottom: PX(10);
      padding: PX(10) 0;
      height: PX(220);
      border-radius: PX(14);
      transition: all 200ms;
      box-sizing: border-box;
      &-gray {
        background: #eee;
        color: #aaa;
      }
      .medicineIcon {
        height: PX(130);
        min-width: PX(90);
        margin-right: PX(30);
      }
      .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: PX(230);
        }
      }
      .rightData {
        position: absolute;
        right: 0;
        font-size: PX(30);
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        .simplyComputed {
          font-size: PX(24);
        }
        .simplyPrice {
          align-items: flex-end;
          flex-direction: column;
        }
      }
    }
    .price-query {
      height: PX(115);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .amount {
        
      }
      .btns {
        .orderBtn {
          border: 1px solid #108ee9;
          outline: none;
          border-radius: 50px;
          height: PX(52);
          color: #108ee9;
          margin-left: PX(40);
          background: transparent;
        }
      }
    }
  }
}
.item.orderDetail{
    height: auto;
    padding-top: 0.86667rem;
}