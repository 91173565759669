@import '../../assets/style/flexible.scss';

.timePicker {
  width: 100%;
  padding: 0 PX(35);
  box-sizing: border-box;
  margin-top: PX(22);
  
  .content {
    padding: PX(23.4) PX(64.4);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    height: PX(263.6);
    box-shadow: 0px 2px 2px 0 rgba(145, 145, 145, 0.185);
    .timeShow {
      width: 100%;
      border-bottom: 1px solid #eee;
      height: PX(49);
      display: flex;
      align-items: center;
      font-size: PX(28);
      .timeIcon {
        height: PX(35);
        width: PX(35);
        margin-right: PX(13);
      }
      .timeLable {
        color: #999;
        margin-right: PX(35);
      }
      .downArrive {
        width: PX(20);
        height: PX(16);
        position: absolute;
        right: PX(130);
      }
      .searchName {
        border: none;
      }
    }
    .inquiryButton {
      color: #fff;
      border-radius: 50px;
      font-size: PX(26);
      background-color: #0081e9;
      width: 100%;
      height: PX(52);
      box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
      outline: none;
      border: none;
      max-width: PX(300);
    }
  }
}
.reportStatistics .tab{
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.reportStatistics .tab .left,.reportStatistics .tab .right{
  width: 50%;
  height: 0.9rem;
  line-height: 0.9rem;
  background: #fff;
  text-align: center;
  justify-content: center;
}
.reportStatistics .tab .left{
  border-right: 1px solid #ddd;
}
.timePicker2 .content{
  height: auto;
}
.timePicker2 .content .timeShow{
  width: auto;
  border: 1px solid #ddd;
  padding: 1px 8px;
  border-radius: 26px;
  background: #dfdfdf;
  position: relative;
}
.timePicker2 .content .timeShow .downArrive{
  right: auto;
  position: relative;
}
.salesStatistics .timePicker{
  padding: 0;
  margin-top: 0;
}
.salesStatistics .orderBtnTime.orderBtn{
  margin-top: 0.312rem;
  top: 1.04rem;
  z-index: 5;
}
.timePicker2 .content{
  position: relative;
  box-shadow: none;
  border-radius: 0;
}
.timePicker2 .content::before{
  border-bottom: 1px solid #ddd;
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  width: 100%;
}
.timePicker4 {
  .am-list-item.am-input-item,.am-list-line{
    height: 0.6rem;
    min-height: 0.6rem;
    width: 100%;
  }
}
.timePicker4 .content{
  height: 3.5rem;
}