@import '../../assets/style/flexible.scss';


.searchBar {
  height: PX(92);
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: PX(52);
  font-size: PX(30);
  border-top: PX(1) solid #e5e5e2;
  border-bottom: PX(1) solid #e5e5e2;
  .searchBarInput {
    display: flex;
    background: #f4f4f4;
    border-radius: 50px;
    align-items: center;
    height: PX(56);
    width: PX(521);
    margin-right: PX(32);
    padding-left: PX(30);
    img {
      width: PX(40);
      height: PX(40);
      margin-right: PX(20);
    }

    input{
      border: none;
      outline: none;
      background: transparent;
    }

  }

  .searchBar-btn {
    background: #1b9ae8;
    color: #fff;
    outline: none;
    border: none;
    border-radius: PX(16);
    height: PX(50);
    padding: 0 PX(20);
    font-size: PX(26);
    font-weight: 300;
  }
}