@import '../../assets/style/flexible.scss';

.reportStatistics {
  width: 100%;
  background: #f4f4f4;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
  }
  
  .reportStatisticsList {
    width: 100%;
    padding: 0 PX(35);
    padding-top: PX(380);
    box-sizing: border-box;
    .item {
      font-size: PX(32);
      width: 100%;
      min-height: PX(200);
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #ddd;
      display: inline-block;
      // display: flex;
      // flex-direction: column;
      margin-bottom: PX(30);
      box-shadow: 0 2px 5px 0 rgba(184, 184, 184, 0.171);
      .medName {
        height: PX(72);
        display: flex;
        align-items: center;
        padding: 0 PX(19);
        box-sizing: border-box;
        letter-spacing: PX(1.2);
        color: #979696;
        img {
          height: PX(42);
          width: PX(42);
          margin-right: PX(20);
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: repeating-linear-gradient(
          90deg,
          transparent 0px,
          transparent 4px,
          #cdcccc 4px,
          #cdcccc 11px);
        // background: -webkit-repeating-linear-gradient( 180deg,transparent, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -moz-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -o-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
      }
      .detailParams {
        flex: 1;
        display: flex;
        font-size: PX(28);
        flex-direction: column;
        justify-content: space-between;
        padding: PX(16) PX(20);
        box-sizing: border-box;
        .param {
          display: flex;
          margin-top: PX(2);
          justify-content: space-between;
          .paramName {
            color: #979696;
          }
        }
      }
    }
  }
  .downLoad {
    position: fixed;
    bottom: PX(30);
    right: PX(30);
    z-index: 3;
    height: PX(120);
    width: PX(120);
  }
}
.flowInquiryInputUrl {
  .tip {
    color: #999;
  }
  input {
    background-color: #eee;
    border-radius: PX(40);
    border: none;
    outline: none;
    width: 90%;
    font-size: PX(40);
    height: PX(80);
    padding: 0 PX(10);
    margin: PX(30) 0;
  }
}
.flowInquiryInputUrlTitle {
  font-size: PX(35);
}
.orderBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  border: 1px solid #108ee9;
  outline: none;
  border-radius: 50px;
  height: 0.69333rem;
  max-width: 2.13333rem;
  color: #108ee9;
  margin-left: 0.13333rem;
  background: transparent;
  float: right;
  margin-top: 0.5rem;
}
.reportStatistics .price-query {
  height: 1.53333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportStatistics .price-query .btns {
  width: 6.66667rem;
  display: flex;
  flex-direction: row-reverse;
}
.orderBtnTime.orderBtn{
  background: #fff;
  float: left;
  margin-left: 0.6rem;
  margin-bottom: 0.3rem;
  padding: 0 0.3rem;
  max-width: inherit;
}
.reportStatistics .timePicker,.orderList .timePicker{
  position: relative;
}
.reportStatistics .timePicker::before,.orderList .timePicker::before{
  display: block;
  width: 100%;
  height: 0.29333rem;
  top: -0.29333rem;
  content: "";
  background: #f4f4f4;
  position: absolute;
  left: 0;
  padding: 0 PX(35);
}
.reportStatistics .orderBtnTime.orderBtn{
  font-size: 0.34667rem;
  bottom: 0.312rem;
  position: absolute;
  margin-bottom: 0;
  left: 1.472rem;
  margin-left: 0.5rem;
}
.reportStatistics .flexCenter{
  justify-content: inherit;
}
.reportStatistics .timePicker .content .inquiryButton{
  float: right;
  width: 100%;
  margin-right: 0.7rem;
}
.reportStatistics .timePicker .flex.flexCenter{
  display: block;
}