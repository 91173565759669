@import '../../assets/style/flexible.scss';

.navbar {
  height: PX(78);
  font-size: PX(42);
  width: 100%;
  background-color: #fff;
  padding: 0 PX(29);
  display: flex;
  align-items: center;
  color: #666;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 0 rgba(145, 145, 145, 0.185);
  .icon {
    margin-right: PX(26);
    font-size: PX(50);
    height: PX(35);
  }
  .title {
    font-size: PX(32);
  }
}
