@import '../../assets/style/flexible.scss';

.submitButton {
  color: #fff;
  border-radius: 50px;
  font-size: PX(39);
  background-color: #0081e9;
  width: 100%;
  height: PX(80);
  margin-top: PX(96);
  box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
  outline: none;
  border: none;
  width: PX(400);
}

.textarea {
  width: 100%;
  .am-list-header {
    font-size: PX(25);
  }
  textarea {
    font-size: PX(32);
  }
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}