@import '../../assets/style/flexible.scss';

.logisticsPopup {
  .bg {
    background: rgba(0, 0, 0, 0.233);
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    z-index: 10;
    .logisticsContent {
      background-color: #fff;
      height: PX(550);
      width: PX(550);
      position: relative;
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-end;
      font-size: PX(30);
      color: #7d7d7d;
      padding: 0 PX(40);
      .bgImg {
        left: 0;
        right: 0;
        position: absolute;
        z-index: 0;
        top: PX(-210);
      }
      .Linput {
        position: relative;
        z-index: 2;
        display: block;
        border: none;
        input {
          border: 1px solid rgb(197, 197, 197);
          padding: PX(16) PX(20);
          outline: none;
          width: PX(480);
          margin-top: PX(20);
          border-radius: PX(16);
        }
      }
      .btns {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        height: PX(140);
        font-size: PX(35);
        align-items: center;
        & label:nth-child(2) {
          color:#508dd8;
          margin-left: PX(40);
          margin-right: PX(40);
        }
      }
    }
  }
  .logisticsList {
    height: 100vh;
    position: fixed;
    z-index: 20;
    width: 100vw;
    right: 0;
    top: 0;
    .blackbg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 0;
      background: rgba(46, 46, 46, 0.144);
    }
    .list {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 0;
      width: PX(400);
      padding-top: PX(100);
      overflow-x: hidden;
      overflow-y: auto;
      color: #777;
      .am-list-item {
        position: fixed;
        top: 0;
      }
      .logisticsItem {
        height: PX(80);
        width: 100%;
        font-size: PX(34);
        border-bottom: 1px solid #ececec
        // color
      }
    }
  }
}