@import '../../assets/style/flexible.scss';

.login {
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.bg {
  position: absolute;
  top: -20px;
  width: 100%;
}
.centerBlock {
  width: 78%;
  height: PX(580);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 4px 1px rgba(151, 151, 151, 0.171);
  z-index: 1;
  display: flex;
  align-items: center;
}

.centerInput {
  width: 100%;
  padding: PX(50);
}

.loginButton {
  color: #fff;
  border-radius: 50px;
  font-size: PX(39);
  background-color: #0081e9;
  width: 100%;
  height: PX(80);
  margin-top: PX(20);
  box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
  outline: none;
  border: none;
}

.input {
  border-bottom: 0.8px solid #ddd;
  display: flex;
  align-items: center;
  height: PX(65);
  padding: PX(10);
  margin-bottom: PX(26);
  .inputIcon {
    height: PX(48);
    width: PX(43)
  }
  .inputFrame {
    outline: none;
    border: none;
    margin-top: PX(8);
    font-size: PX(31);
    margin-left: PX(20);
    color: #666;
    width: PX(380);
  }
  .lockIcon {
    height: PX(45);
    width: PX(45);
  }
}
