@import '../../assets/style/flexible.scss';

.noResult {
  width: 100%;
  flex-direction: column;
  img {
    height: PX(237);
    width: PX(400);
  }
  .noResultTip {
    margin-top: PX(20);
    color: #999;
    font-size: PX(30);
  }
}