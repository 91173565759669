@import '../../assets/style/flexible.scss';

.repository {
  height: 100vh;
  background: #f4f4f4;
  .am-list .am-list-body .am-list-item .am-list-line .am-input-control input {
    font-size: PX(32);
  }
  .am-list .am-list-body .am-list-item .am-list-line .am-input-label {
    font-size: PX(32);
  }
  .repository-List {
    margin-top: PX(30);
  }
}