@import '../../../assets/style/flexible.scss';

.salesmanList {
  height: 100%;
  background: #f4f4f4;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  .repostHeader {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 99999;
  }

  .reposReultList {
    width: 100%;
    padding: 0 PX(35);
    padding-top: PX(190);
    box-sizing: border-box;
    .item {
      font-size: PX(32);
      width: 100%;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      margin-bottom: PX(30);
      box-shadow: 0 2px 5px 0 rgba(184, 184, 184, 0.171);
      .medName {
        height: PX(72);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 PX(19);
        box-sizing: border-box;
        letter-spacing: PX(1.2);
        color: #979696;
        img {
          height: PX(42);
          width: PX(42);
          margin-right: PX(20);
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: repeating-linear-gradient(
          90deg,
          transparent 0px,
          transparent 4px,
          #cdcccc 4px,
          #cdcccc 11px);
        // background: -webkit-repeating-linear-gradient( 180deg,transparent, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -moz-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -o-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
      }
      .detailParams {
        flex: 1;
        display: flex;
        font-size: PX(28);
        flex-direction: column;
        justify-content: space-between;
        padding: PX(16) PX(20);
        box-sizing: border-box;
        .param {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: PX(8) 0;
          .paramName {
            color: #979696;
          }
          .paramPrice {
            color: #f00
          }
        }
      }
    }
  }
}