
@import '../../assets/style/flexible.scss';

.orderList {
  width: 100%;
  height: 100vh;
  background: #f4f4f4;
  overflow-x: unset;
  overflow-y: auto;

  .downLoad {
    position: fixed;
    bottom: PX(30);
    right: PX(30);
    z-index: 3;
    height: PX(120);
    width: PX(120);
  }
}

.am-tabs-default-bar-underline {
  position: absolute;
  border: 0px #108ee9 solid;
  background: #108ee9;
  height: 3px;
  border-radius: 4px;
  z-index: 2;
  transform: translate3d(0, -8px, 0) scaleX(0.55);
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.list {
  padding: PX(460) PX(44) 0 PX(44);
  width: 100%;
  box-sizing: border-box;
  .orderItem {
    min-height: PX(400);
    width: 100%;
    background: #fff;
    border-radius: PX(16);
    font-size: PX(26);
    color: #888;
    padding: PX(16) PX(36);
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: PX(20);
    justify-content: space-between;
    .storeName {
      display: flex;
      align-items: center;
      margin-top: PX(16);
      .storeIcon {
        height: PX(33);
        width: PX(33);
        margin-right: PX(15);
      }
      .red {
        color: rgb(248, 37, 0);
      }
      .phoneIcon {
        height: PX(34);
        width: PX(34);
      }
    }
    .zeroMargin{
      margin-top: 0px;
    }

    .time-state {
      display: flex;
      align-items: center;
      position: relative;
      .stateType {
        position: absolute;
        right: 0;
        color: orange;
        top: 0;
        padding-top: PX(10);
        img {
          height: PX(170);
          width: PX(170);
        }
      }
    }
    .icon-detail {
      display: flex;
      margin-top: PX(20);
      align-items: center;
      .medicineIcon {
        height: PX(130);
        width: PX(130);
        // margin-top: PX(50);
      }
      .detail {
        flex: 1;
        height: PX(130);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .priceValue {
          margin-top: PX(20);
        }
        .detail-field {
          align-items: center;
          white-space: nowrap;
          justify-content: space-between;
        }
      }
    }
    .price-query {
      height: PX(115);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .amount {
        white-space: nowrap;
      }
      .btns {
        width: PX(500);
        display: flex;
        flex-direction: row-reverse;
        .orderBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border: 1px solid #108ee9;
          outline: none;
          border-radius: 50px;
          height: PX(52);
          max-width: PX(160);
          color: #108ee9;
          margin-left: PX(10);
          background: transparent;
        }
      }
    }
  }
}
