@import '../../../assets/style/flexible.scss';

.noticeDetail {
  width: 100%;
  background: #f4f4f4;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
  }
  
  .noticeDetailList {
    width: 100%;
    padding: 0 PX(35);
    padding-top: PX(115);
    box-sizing: border-box;
    .item {
      font-size: PX(32);
      width: 100%;
      min-height: PX(390);
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #ddd;
      display: inline-block;
      // display: flex;
      // flex-direction: column;
      margin-bottom: PX(30);
      box-shadow: 0 2px 5px 0 rgba(184, 184, 184, 0.171);
      .medName {
        height: PX(72);
        display: flex;
        align-items: center;
        padding: 0 PX(19);
        box-sizing: border-box;
        letter-spacing: PX(1.2);
        color: #979696;
        img {
          height: PX(42);
          width: PX(42);
          margin-right: PX(20);
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: repeating-linear-gradient(
          90deg,
          transparent 0px,
          transparent 4px,
          #cdcccc 4px,
          #cdcccc 11px);
        // background: -webkit-repeating-linear-gradient( 180deg,transparent, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -moz-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
        // background: -o-repeating-linear-gradient( 180deg, transparent 0px 4px, #cdcccc 4px 11px);
      }
      .detailParams {
        flex: 1;
        display: flex;
        font-size: PX(28);
        flex-direction: column;
        justify-content: space-between;
        padding: PX(16) PX(20);
        box-sizing: border-box;
        .param {
          display: flex;
          margin-top: PX(2);
          justify-content: space-between;
          .paramName {
            color: #979696;
          }
        }
        .icon-detail {
          display: flex;
          margin-top: PX(10);
          position: relative;
          margin-bottom: PX(10);
          padding: PX(10) 0;
          height: PX(160);
          border-radius: PX(14);
          transition: all 200ms;
          box-sizing: border-box;
          font-size: PX(26);
          &-gray {
            background: #eee;
            color: #aaa;
          }
          .medicineIcon {
            height: PX(130);
            min-width: PX(90);
            margin-right: PX(30);
          }
          .detail {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              width: PX(230);
            }
          }
          .rightData {
            position: absolute;
            right: 0;
            font-size: PX(26);
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            .simplyComputed {
              font-size: PX(24);
            }
            .simplyPrice {
              align-items: flex-end;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  .downLoad {
    position: fixed;
    bottom: PX(30);
    right: PX(30);
    z-index: 3;
    height: PX(120);
    width: PX(120);
  }
}