@import '../../assets/style/flexible.scss';


.home {
  width: 100%;
  min-height: 100vh;
  padding: PX(60) PX(52);
  padding-bottom: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(180deg, #1154de 10%, #fff 30%, #fff 100%);
}

.homeBg {
  width: 100%;
  height: PX(580);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
}
.userInfo {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  color: #fff;
  margin-bottom: PX(32.2);
  .avatar {
    height: PX(93.75);
    width: PX(93.75);
    background-color: #eee;
    border-radius: 50%;
  }

  .baseInfo {
    margin-left: PX(20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .role {
      height: PX(32.2);
      display: flex;
      font-size: PX(24);
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: #3168ad;
      padding: 0 PX(18);
      margin-bottom: PX(10);
      flex: 0;
    }

    .userName {
      font-size: PX(27);
    }
  }

  .logout {
    height: PX(52);
    line-height: PX(52);
    font-size: PX(30);
    position: absolute;
    border-radius: 50px 0 0 50px;
    background-color: #206bfd;
    right: PX(-52);
    padding: 0 PX(20);
  }
}

.salesVolume {
  color: #fff;
  height: PX(300);
  display: flex;
  position: relative;
  border-radius: PX(8);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 2px 3px 0px rgba(228, 228, 228, 0.212);
  padding: PX(46) PX(32);
  font-size: PX(30);
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: PX(46);
  .volume {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 4px 0;
    width: 50%;
  }
  .volume1 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 4px 0;
    width: 100%;
  }
}

.swiper {
  position: relative;
  height: PX(270) !important;
  width: 100%;
  .slider-frame {
    height: PX(270) !important;
  }
}
.dotGroup {
  position: absolute;
  top: 0;
}
.routeIcons {
  position: relative;
  margin-top: PX(46);
  .routeIcon {
    float: left;
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: PX(24);
    img {
      height: PX(106);
      width: PX(106);
      margin-bottom: PX(18);
    }
    label {
      font-size: PX(28);
      font-weight: 300;
    }
  }
}