@import '../../assets/style/flexible.scss';

.logistics {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background: #f4f4f4;
  padding: 0 PX(40);
  padding-top: PX(100);
  box-sizing: border-box;
  .bgImage {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
  }
  .header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    left: 0;
  }
  .expressId {
    box-shadow: 0px 2px 2px 0 rgba(145, 145, 145, 0.185);
    height: PX(120);
    padding: PX(10) PX(30);
    box-sizing: border-box;
    border-radius: PX(14);
    width: 100%;
    background: #fff;
    display: flex;
    font-size: PX(30);
    color: #666;
    align-items: center;
    position: relative;
    z-index: 1;
    .expressOrderIcon {
      width: PX(66);
      height: PX(80);
      margin-right: PX(20);
    }
    .stateAndId {
      .state {
        
      }
      .ID {
        
      }
    }
  }
  .logisticsContent {
    box-shadow: 0px 2px 2px 0 rgba(145, 145, 145, 0.185);
    margin-top: PX(35);
    padding: PX(40) PX(35);
    box-sizing: border-box;
    border-radius: PX(14);
    width: 100%;
    background: #fff;
    display: flex;
    font-size: PX(30);
    color: #666;
    position: relative;
    z-index: 1;
    flex-direction: column;
    .logisticsTimespan {
      // height: 
      display: flex;
      position: relative;
      padding-bottom: PX(50);
      color: #aaa;
      .time {
        display: inline-block;
        width: PX(120);
        font-size: PX(20);
      }
      .status {

        width: 0px;
        display: inline-block;
        margin: 0 PX(30);
        position: relative;
        margin-bottom: PX(-50);
        border-right: 1px solid #ccc;
        img {
          background-color: #fff;
          height: PX(55);
          border-radius: 50%;
          width: PX(55);
          position: absolute;
          transform: translateX(-50%);
        }
        .point {
          background-color: #ccc;
          height: PX(14);
          width: PX(14);
          border-radius: 50%;
          transform: translateX(-50%);
        }
      }
      .statusAndContext {
        display: inline-block;
        color: #666;
        display: flex;
        width: PX(500);
        flex-direction: column;
        .statusName {
          font-size: PX(26);
          margin-bottom: PX(4);
        }
        .context {
          font-size: PX(22);
        }
      }
    }
  }
}