
@import '../../assets/style/flexible.scss';
.bottomRefresh {
  width: 100%;
  height: PX(80);
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    .loadingTip1 {
      width: PX(100);
      white-space: nowrap;
    }
    .loadingLable {
      margin-left: PX(20);
      font-size: PX(24);
      color: #999;
    }
    .loadingTip {
      color: #666;
      font-size: PX(24);
      display: flex;
      justify-content: center;
      .loadMore {
        width: 100%;
        text-align: center;
        font-size: PX(28);
      }
    }
  }
}