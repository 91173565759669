@import '../../assets/style/flexible.scss';

.tabs {
  width: 100%;
  padding: 0 PX(33);
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: PX(69);
  background: #fff;
  .tabBar {
    min-width: PX(69.3);
    position: relative;
    height: 100%;
    height: PX(48);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    .tabTitle {
      font-size: PX(28);
      margin-bottom: PX(8);
    }
    .tabLine {
      position: absolute;
      height: PX(8);
      width: 100%;
      bottom: 0;
      background: #3785e5;
    }
  }
}

.selectTab {
  font-weight: 900 !important;
}