@import '../../assets/style/flexible.scss';

.createOrder {
  width: 100%;
  padding-top: PX(80);
  background: #f4f4f4;
  height: 100vh;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
  }

  .orderBody {
    padding: PX(44.3);
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    .orderBlock {
      border-radius: 8px;
      background: #fff;
      padding: PX(21);
      margin-bottom: PX(20);
      color: #777;
      .name {
        font-size: PX(28);
        .tip {
          font-size: PX(22);
          font-weight: 300;
        }
      }
      .cilentName {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: PX(14);
        .clientLabel {
          display: flex;
          align-items: center;
          .storeIcon {
            height: PX(46);
            width: PX(46);
            margin-right: PX(20);
            display: flex;
            justify-content: space-between;
          }
          
        }
        .selectArrive {
          display: flex;
          align-items: center;
          .arriveIcon {
            height: PX(36);
            width: PX(36);
            margin-left: PX(16);
            transform: rotate(180deg);
          }
        }
      }
      .content {
        .textarea {
          outline: none;
          border: none;
          font-size: PX(25);
          height: PX(70);
        }
        .imagePicker {
          min-height: PX(200);
          width: 100%;
        }
        .addGoods {
          font-size: PX(30);
          color: #00a2e8;
          height: PX(87.8);
          width: 100%;
          display: flex;
          justify-content: space-around;
          .plusIcon {
            width: PX(42);
            height: PX(42);
            margin-right: PX(20);
          }
          .deleteIcon {
            color: #fc643a;
          }
        }
        .medicineItem {
          display: flex;
          // flex-direction: column;
          height: PX(210);
          font-size: PX(26);
          width: 100%;
          border-bottom: 1px solid #ddd;
          margin-top: PX(20);
          .top {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .checkbox {
              width: PX(40);
              height: PX(40);
              margin-right: PX(13);
            }
            .reposIcon {
              width: PX(160);
              height: PX(160);
              margin-right: PX(60);
            }
            .selectArrive {
              display: flex;
              align-items: center;
              .arriveIcon {
                height: PX(36);
                width: PX(36);
                margin-left: PX(16);
                transform: rotate(180deg);
              }
            }
          }
          .line {
            width: 100%;
            height: 1px;
            background: repeating-linear-gradient(
              90deg,
              transparent,
              transparent 0px 4px,
              #cdcccc 4px 11px);
          }
          .bottom {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .price {
              font-size: PX(24);
              display: flex;
              padding-right: PX(20);
              justify-content: space-between;
              input {
                outline: none;
                border: none;
                width: PX(100);
                text-align: center;
                border-bottom: 1px solid #ddd;
              }
            }
          }
        }
       }
    }
    .createOrderSubmit {
      width: 100%;
      height: PX(111);
      background-color: #fff;
      display: flex;
      position: fixed;
      bottom: 0;
      .left {
          flex: 1;
          display: flex;
          align-items: center;
        .allCheck {
          
        }
        .allCheckLabel {

        }
      }
      .right {
        width: PX(250);
        display: flex;
        .delete {
          flex: 1;
        }
        .submit {
          flex: 1;
          background: #1197db
        }
      }
    }
  }
  .createOrderSubmit {
    width: 100%;
    height: PX(111);
    background-color: #fff;
    display: flex;
    position: fixed;
    bottom: 0;
    .left {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: PX(30);
        padding-left: PX(30);
      .allCheck {
        height: PX(39);
        width: PX(39);
        margin-right: PX(20);
      }
      .allCheckLabel {
        margin-right: PX(28);
      }
      .count {
        font-size: PX(30);
        .amount {
          color: #1197db;
          margin-left: PX(20);
        }
      }
    }
    .right {
      width: PX(250);
      display: flex;
      font-size: PX(30);
      .delete {
        flex: 1;
      }
      .submit {
        color: #fff;
        flex: 1;
        background: #1197db
      }
    }
  }
  .createOrderButton {
    color: #fff;
    border-radius: 50px;
    font-size: PX(39);
    background-color: #0081e9;
    width: 100%;
    height: PX(80);
    box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
    outline: none;
    border: none;
    width: PX(400);
    margin-top: PX(30);
  }
}
