.flowInquiry .salesStatisticsList{
    padding-top: 4.26667rem;
}
.colorBoxWrap{
    height: 2rem;
}
.colorBoxWrap
.colorBox{
    width: 1rem;
    height: 0.5rem;
    clear: both;
    margin-top: 0.2rem;
    border-radius: 3px;;
    margin-left:0.2rem;
}
.colorBoxText{
    margin-top: 0.2rem;
    margin-left: 0.2rem;
}
.colorBoxWrap div{
    float: left;
}
.colorBoxNumber{
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    display: none;
    padding: 0.1rem;
    background: rgba(0, 0, 0, 0.5);
    min-width: 1rem;
    height: 0.5rem;
    color: #fff;
    text-align: center;
    border-radius: 0.2rem;
    padding: 0.1rem 0.2rem;

}
.colorBoxP:hover .colorBoxNumber{
    display: block;
}
.salesStatistics .orderBtnTime.orderBtn{
    background: #3aa4f1;
    box-shadow: 0 0 3px #108ee9;
    color:#fff;
}
.statisticsBox{
    display: flex;
    background: #fff;;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(204, 185, 185);
    position: relative;
    height: 1rem;
}
.statisticsBox::after{
    position: absolute;
    display: block;
    height: 1.188rem;
    border-left: 1px solid #ddd;
    left: 50%;
    content: "";
    top: 0rem;
}
.statisticsBox>div{
    width: 50%;
    text-align: center;
}
.statisticsBox .title{
    font-size: 0.455rem;
    font-weight: 700;
}
.salesStatistics .timePicker::before, {
    background: #fff;
}