// @import './assets/style/flexible.scss';
@import '../node_modules/antd-mobile/dist/antd-mobile.css';

@function PX($value) {
  @return $value / 750 + rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}

.flex {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.submitButton {
  color: #fff;
  border-radius: 50px;
  font-size: PX(39);
  background-color: #0081e9;
  width: 100%;
  height: PX(80);
  box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
  outline: none;
  border: none;
  width: PX(400);
  margin-top: PX(40);
}
.red {
  color: rgb(248, 37, 0);
}