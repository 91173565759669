@import '../../assets/style/flexible.scss';

.modify-password {
  background: #f4f4f4;
  height: 100vh;
  .am-list-item .am-list-line .am-input-control input {
    font-size: PX(32);
  }
}

.pwdTip {
  color: #aaa;
  font-size: PX(24);
  width: 100%;
  text-align: center;
  margin-top: PX(30);
  margin-bottom: PX(80);
  
}

.modifyPasswordBtton {
  color: #fff;
  border-radius: 50px;
  font-size: PX(39);
  background-color: #0081e9;
  width: 100%;
  height: PX(80);
  box-shadow: 0px 3px 5px 1px rgba(143, 143, 143, 0.295);
  outline: none;
  border: none;
  width: PX(400);
  margin-top: 0;
}

.flex {
  width: 100%;
}
